import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, Row, Col, Label, Input, Button, Alert } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import firebase from '../../firebase';

const initialValues = {
  name: '',
  email: '',
  company: '',
  subject: '',
  message: '',
};

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Please Enter Your Email'),
  name: Yup.string().required('Please Enter Your Name'),
  company: Yup.string().required('Please Enter Company Name'),
  subject: Yup.string().required('Please Enter Your Subject'),
  message: Yup.string().required('Please Enter Your Message'),
});

const ContactForm = () => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        // Call the sendEmail cloud function here
        const sendEmail = firebase.functions().httpsCallable('sendEmail');
        await sendEmail(values);
        console.log('Email sent successfully!');
        resetForm();
        toast.success('Message Sent Successfully', {
          position: 'top-right',
          hideProgressBar: false,
          className: 'bg-success text-white',
          toastId: '',
        });
      } catch (error) {
        console.error('Error sending email:', error);
        toast.error('Oops! Something went wrong, please try again later.', {
          position: 'top-right',
          hideProgressBar: false,
        });
      }
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit} className='contact-widget'>
      <Row className='row-control'>
        <Col md={6} lg={6} xl={6}>
          <Label for="name" className="form-label">Name <span className="text-danger">*</span></Label>
          <Input
            type="text"
            id="name"
            name="name"
            className="form-control custom-input"
            placeholder="Enter your name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="text-danger">{formik.errors.name}</div>
          ) : null}
        </Col> 

        <Col md={6} lg={6} xl={6}>
          <Label for="email" className="form-label">Email <span className="text-danger">*</span></Label>
          <Input
            type="email"
            id="email"
            name="email"
            className="form-control custom-input"
            placeholder="Enter email address"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-danger">{formik.errors.email}</div>
          ) : null}
        </Col>
      </Row>

      <Row className='row-control'>
        <Col md={6} lg={6} xl={6}>
          <Label for="company" className="form-label">Company <span className="text-danger">*</span></Label>
          <Input
            type="text"
            id="company"
            name="company"
            className="form-control custom-input"
            placeholder="Enter company name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.company}
          />
          {formik.touched.company && formik.errors.company ? (
            <div className="text-danger">{formik.errors.company}</div>
          ) : null}
        </Col>

        <Col md={6} lg={6} xl={6}>
          <Label for="subject" className="form-label">Subject <span className="text-danger">*</span></Label>
          <Input
            type="text"
            id="subject"
            name="subject"
            className="form-control custom-input"
            placeholder="Enter the subject"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.subject}
          />
          {formik.touched.subject && formik.errors.subject ? (
            <div className="text-danger">{formik.errors.subject}</div>
          ) : null}
        </Col>
      </Row>
      <Row className='row-control'>
        <Col md={12} lg={12} xl={12}>
          <Label for="message" className="form-label">Message <span className="text-danger">*</span></Label>
          <Input
            type="textarea"
            id="message"
            name="message"
            className="custom-input textarea"
            placeholder="Enter your message"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.message}
          />
          {formik.touched.message && formik.errors.message ? (
            <div className="text-danger">{formik.errors.message}</div>
          ) : null}
        </Col>
      </Row>
      <button type="submit" className='btn-secondary mt-18'>Submit</button>
      <ToastContainer autoClose={2000} className='z-15000 mt-80' limit={1} />
    </Form>
  );
};

export default ContactForm;
