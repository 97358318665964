import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';

// Breadcrumbs Image
import breadcrumbsImg from '../../assets/img/breadcrumbs/1.jpg';

import { Link } from 'react-router-dom';
//import Brand from '../../components/Common/Brand';
import SectionTitle from '../../components/Common/SectionTitle';
import ScrollToTop from '../../components/Common/ScrollTop';

// About Image
import aboutImg1 from '../../assets/img/about/1.jpg';

const SoftwareDevelopment = () => {
    return (
        <React.Fragment>
            <Header
                parentMenu='Services'
                activeMenu='/software-development'
            />
            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                breadcrumbsImg={breadcrumbsImg}
                pageTitle='Software Development'
                pageCategory='Services'
                pageName='Software Development'
            />
            {/* breadcrumb-area-end */}
            {/* About Section Start */}
            <div className="about pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title4"
                                subtitleClass="sub-text"
                                subtitle="Software Development"
                                titleClass="title"
                                title="We Are Specialized in Web-Based Software Applications - SaaS"
                                effectClass="heading-line"
                            />
                            <SectionTitle
                                sectionClass="sec-title4"
                                descClass="desc desc-big"
                                description="As the IntellApps is a web solutions agency, we create lightweight-programmed web-based applications Software as a Service (SaaS). These applications can be hosted in the intranet server if needed as well as in a hosting server."
                                secondDescClass="desc"
                                secondDescription="Web-based applications are more in demand in a market with continuously increasing use of the Internet and availability of resources at every place even through mobile and personal laptops. Web-based applications have a wider range of customisation and perform better in data centralization or multi-user environment. 
                                We are also specialized moving enterprise software to the cloud which is thought to be the megatrend of the last decade. In the vast majority of cases, it is the right thing to do, even for mission-critical enterprise software."
                            />
                            {/* Section Title End */}
                            <div className="btn-part mt-46">
                                <Link to="/contact">
                                    <a className="readon learn-more">Contact Us</a>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="images">
                                <img src={aboutImg1} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* About Section End */}

            {/* brand-area-start */}
            {/* <Brand /> */}
            {/* brand-area-end */}

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}
            <Footer footerClass="footer" />
            <SearchModal />
        </React.Fragment>
    );
}

export default SoftwareDevelopment;