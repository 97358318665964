import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import { Link } from 'react-router-dom';

// Breadcrumbs Image
import breadcrumbsImg from '../../assets/img/breadcrumbs/2.jpg';
//import Brand from '../../components/Common/Brand';
import SectionTitle from '../../components/Common/SectionTitle';
import ScrollToTop from '../../components/Common/ScrollTop';
import aboutImg1 from '../../assets/img/about/10.png';

const WebDevelopment = () => {
    return (
        <React.Fragment>
            <Header
                parentMenu='Services'
                activeMenu='/analytic-solutions'
            />
            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                breadcrumbsImg={breadcrumbsImg}
                pageTitle='Analytic Solutions'
                pageCategory='Services'
                pageName='Analytic Solutions'
            />
            {/* breadcrumb-area-end */}
            {/* About Section Start */}
            <div className="about pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title4"
                                subtitleClass="sub-text"
                                subtitle="Analytic Solutions"
                                titleClass="title"
                                title="Turn to revenue the behaviour of visitors on your website."
                                effectClass="heading-line"
                            />
                            <SectionTitle
                                sectionClass="sec-title4"
                                descClass="desc desc-big"
                                description="Web analytics is a process of analyzing how visitors behave on your website. Web analytics tools gather data on your site’s performance, organize it accurately, and present it, which is used to discover how successful your SEO efforts have been."
                                secondDescClass="desc"
                                secondDescription="The web analytics tools that we provide will tell you how many visitors your site has had over a specified period, whether all of these visitors have viewed your site before or if there are any new visitors, where your site is generating the most views from, as well as which pages and products are the most popular."
                            />
                            {/* Section Title End */}
                            <div className="btn-part mt-46">
                                <Link to="/contact">
                                    <a className="readon learn-more">Contact Us</a>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="images">
                                <img src={aboutImg1} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* About Section End */}

            {/* brand-area-start */}
            {/* <Brand /> */}
            {/* brand-area-end */}

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}
            <Footer footerClass="footer" />
            <SearchModal />
        </React.Fragment>
    );
}

export default WebDevelopment;