import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import { Link } from 'react-router-dom';

// Breadcrumbs Image
import breadcrumbsImg from '../../assets/img/breadcrumbs/1.jpg';

//import Brand from '../../components/Common/Brand';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleService from '../../components/Service/SingleService';
import ScrollToTop from '../../components/Common/ScrollTop';

// Service Image Icons
import mainIcon1 from '../../assets/img/service/style3/main-img/1.png';
import hoverIcon1 from '../../assets/img/service/style3/hover-img/1.png';
import mainIcon2 from '../../assets/img/service/style3/main-img/2.png';
import hoverIcon2 from '../../assets/img/service/style3/hover-img/2.png';
import mainIcon3 from '../../assets/img/service/style3/main-img/3.png';
import hoverIcon3 from '../../assets/img/service/style3/hover-img/3.png';
import mainIcon4 from '../../assets/img/service/style3/main-img/4.png';
import hoverIcon4 from '../../assets/img/service/style3/hover-img/4.png';

import bannerImg from '../../assets/img/banner/style2/bnr-ly2.png';
import shapeImg from '../../assets/img/banner/style2/bnr-ly1.png';

import bgImg from '../../assets/img/bg/banner-9.jpg';

const bgStyle = {
    backgroundImage: `url(${bgImg})`
}


const SoftwareDevelopment = () => {
    return (
        <React.Fragment>
            <Header
                parentMenu='Services'
                activeMenu='/web-development'
            />
            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                breadcrumbsImg={breadcrumbsImg}
                pageTitle='Web Development'
                pageCategory='Services'
                pageName='Web Development'
            />
            {/* breadcrumb-area-end */}

            {/* About Section Start */}
            <div className="banner  style5" style={bgStyle}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-8">
                            <div className="banner-content">

                                {/* Section Title Start */}
                                <SectionTitle
                                    sectionClass="sec-title4"
                                    subtitleClass="sub-text style-bg"
                                    subtitle="Web Development"
                                    titleClass="title"
                                    title="Have Your Ideas Online with Trending Technology"
                                    effectClass="heading-line"
                                />
                                <SectionTitle
                                    sectionClass="sec-title4"
                                    descClass="desc desc-big"
                                    description="Failing to keep up with new trends can result in serious consequences for your website performance and user experience. You may fall behind your competitors and lose traffic to websites offering better experiences."
                                    secondDescClass="desc"
                                    secondDescription="Developing and maintaining your website according to ongoing trends is the best way to remain competitive in your business niche. This is what we do for you!!"
                                />
                                {/* Section Title End */}
                                <div className="btn-part">
                                    <Link className="readon started get-new" to="/about">Get Started</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="banner-img">
                    <div className="images-part hidden-sm">
                        <img
                            src={bannerImg}
                            alt=""
                        />
                    </div>
                    <img
                        className="layer-img"
                        src={shapeImg}
                        alt=""
                    />
                </div>
            </div>
            {/* About Section End */}

            {/* About Section2 Start */}
            <div id="about" className="about pt-120 pb-120 md-pt-70 md-pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <SectionTitle
                                sectionClass="sec-title4"
                                subtitleClass="sub-text"
                                subtitle="We Make It"
                                titleClass="title black-color pb-8"
                                title="We Turn Your Ideas into Automation"
                                effectClass="heading-line"
                            />
                            <SectionTitle
                                sectionClass="sec-title4"
                                descClass="desc desc-big"
                                description="Every business would like to automate a part of its works. Today's technologies allow us to build web applications performing all types of functions without a need of building local software. Considering these points, and for better UX and for automation of your work, we add AI along with various latest web application functionalities to your website. "
                                secondDescClass="desc"
                                secondDescription="For better UX, we define different values for all parts of a website uniquely for Desktop, Tablet, and Mobile devices. Which means your website will be desktop, tablet, and phone friendly without needing any other challenges like building the website on different add-on domains for each device type. "
                            />
                        </div>
                        <div className="col-lg-7">
                            <div className="services style-web modify3 md-pt-50">
                                <div className="row">
                                    <div className="col-md-6 pt-40 sm-pt-0">
                                        <SingleService
                                            itemClass="services-item orange-bg mb-20"
                                            mainIcon={mainIcon1}
                                            hoverIcon={hoverIcon1}
                                            Title="High-quality Code"
                                            Text="We make sure that codes are written by us are highly readable and changable is needed."
                                        />
                                        <SingleService
                                            itemClass="services-item green-bg"
                                            mainIcon={mainIcon2}
                                            hoverIcon={hoverIcon2}
                                            Title="Agile Approach"
                                            Text="We make an iterative approach to product development that is performed in a collaborative environment by self-organizing teams."
                                        />
                                    </div>
                                    <div className="col-md-6 sm-mt-20">
                                        <SingleService
                                            itemClass="services-item blue-bg mb-20"
                                            mainIcon={mainIcon3}
                                            hoverIcon={hoverIcon3}
                                            Title="Full-stack teams"
                                            Text="Having Full-stack Teams fasten the solving unbalanced complexities between the front-end and back-end at one place."
                                        />
                                        <SingleService
                                            itemClass="services-item cyan-bg"
                                            mainIcon={mainIcon4}
                                            hoverIcon={hoverIcon4}
                                            Title="Improved Native Cybersecurity"
                                            Text="With an alarming increase in cyberattacks, we have shifted to make it easier to protect company and customer data through Multi-Factor Authentication (MFA)."
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* About Section2 End */}

            {/* brand-area-start */}
            {/* <Brand /> */}
            {/* brand-area-end */}

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}
            <Footer footerClass="footer" />
            <SearchModal />
        </React.Fragment>
    );
}

export default SoftwareDevelopment;