import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';

// Breadcrumbs Image
import breadcrumbsImg from '../../assets/img/breadcrumbs/appDev.jpg';


import { Link } from 'react-router-dom';
//import Brand from '../../components/Common/Brand';
import SectionTitle from '../../components/Common/SectionTitle';
import TestimonialData from '../../components/Testimonial/TestimonialData';
import Testimonial from '../../components/Testimonial/Testimonial';
import ScrollToTop from '../../components/Common/ScrollTop';

import aboutImg1 from '../../assets/img/about/2.jpg';


const WebDevelopment = () => {
    return (
        <React.Fragment>
            <Header
                parentMenu='Services'
                activeMenu='/app-development'
            />
            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                breadcrumbsImg={breadcrumbsImg}
                pageTitle='Mobile App Development'
                pageCategory='Services'
                pageName='Mobile App Development'
            />
            {/* breadcrumb-area-end */}

            {/* About Section Start */}
            <div className="about pt-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row align-items-center lg-mb-50">
                        <div className="col-lg-6 pr-40 md-pr-14">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title4"
                                subtitleClass="sub-text"
                                subtitle="Mobile   App  Development"
                                titleClass="title"
                                title="IntellApps Apps for Professionals, the Power to Build Your Business"
                                effectClass="heading-line"
                            />
                            <SectionTitle
                                sectionClass="sec-title4"
                                descClass="desc desc-big"
                                description="Whenever you are looking to leverage the power of mobile app development solutions, IntellApps laboratory is here to support you with this task. Trending technologies help businesses across all domains to grow revenues, to win new competitive advantages, and to stand out with their products or services. It is exactly what IntellApps does whose products win the hearts and minds of your customers."
                                secondDescClass="desc"
                                secondDescription="In the post-Covid19 world, much fuel has been added to reliance on smartphones and consequently mobile app development became inevitable. In coming years, thanks to new technologies like 5G and edge computing, mobile app usage is going to be much bigger. Don't fall behind, let us consult your ideas!!"
                            />
                            {/* Section Title End */}
                            <div className="btn-part mt-46 mb-60">
                                <Link to="/contact">
                                    <a className="readon learn-more">Contact Us</a>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="images">
                                <img src={aboutImg1} alt="about" />
                            </div>
                        </div>
                    </div>
                </div>
                
                <Testimonial TestimonialData={TestimonialData} />
                
            </div>
            {/* About Section End */}

            {/* brand-area-start */}
            {/* <Brand /> */}
            {/* brand-area-end */}

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}
            <Footer footerClass="footer" />
            <SearchModal />
        </React.Fragment>
    );
}

export default WebDevelopment;